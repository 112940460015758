import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import { Account } from "@services/account";
import { AccountStorage } from "@services/account";
// 分页
import pager from '@/components-v2/pager/index.vue';
var account = new Account();
var accountStorage = new AccountStorage();
import dialogLaoding from '@/components-v2/v2-dialog/index.vue';
export default {
  components: {
    pager: pager,
    dialogLaoding: dialogLaoding
  },
  data: function data() {
    return {
      isShowRuler: false,
      logging: {},
      //弹窗回显
      isLogin: false,
      isClickActive: false,
      //是否勾选了
      total: null,
      current: 1,
      // 页码
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      type: 0,
      status: "",
      appId: 1,
      // 判断是App还是商城
      messageList: [],
      readStatus: "",
      pageInfo: {
        total: null,
        pageNum: 1,
        pageSize: 50
      },
      navList: [{
        id: 1,
        name: "全部"
      }, {
        id: 2,
        name: "订单交易类"
      }, {
        id: 3,
        name: "通知公告类"
      }, {
        id: 4,
        name: "促销活动类"
      }]
    };
  },
  created: function created() {
    this.isLogin = account.isLogined();
    this.getMessageList();
  },
  methods: {
    showChange: function showChange() {
      this.isShowRuler = false;
    },
    loggingChange: function loggingChange(e) {
      this.logging = e;
      this.isShowRuler = true;
    },
    getDataByPage: function getDataByPage(pageNum) {
      this.current = pageNum;
      this.getMessageList();
    },
    // 消息列表
    getMessageList: function getMessageList() {
      var _this = this;
      var url = "/xpaas-message-center/api/v1/message/pm/pool/findByApp";
      var data = {
        appId: 1,
        userId: Number(this.getUserId()),
        type: this.status,
        readStatus: this.readStatus,
        page: this.current,
        size: this.perPage,
        companyAccount: Number(this.getAccount())
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        _this.messageList = res.data.data.content;
        _this.pageInfo.total = res.data.data.totalElements;
        _this.pageInfo.pageNum = _this.current;
        _this.pageInfo.pageSize = res.data.data.size;
      });
    },
    // 左侧tab
    handleNavList: function handleNavList(index) {
      this.type = index;
      switch (index) {
        case 0:
          this.status = "", this.isClickActive = false;
          this.readStatus = "";
          return this.getMessageList();
        case 1:
          this.status = "1";
          this.isClickActive = false;
          this.readStatus = "";
          this.current = 1;
          return this.getMessageList();
        case 2:
          this.status = "2";
          this.isClickActive = false;
          this.readStatus = "";
          this.current = 1;
          return this.getMessageList();
        case 3:
          this.status = "3";
          this.isClickActive = false;
          this.readStatus = "";
          this.current = 1;
          return this.getMessageList();
      }
    },
    // 已读
    getUpdatePmMessagePool: function getUpdatePmMessagePool(id) {
      var url = "/xpaas-message-center/api/v1/message/pm/pool/updatePmMessagePool?id=".concat(id);
      this.$getHttpClient().get(url);
    },
    //获取用户ID
    getUserId: function getUserId() {
      var userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    // 获取公司账号
    loginCompanyAccount: function loginCompanyAccount() {
      var loginCompanyAccount = accountStorage.getCacheUserInfo();
      if (loginCompanyAccount) {
        return loginCompanyAccount.loginCompanyAccount;
      }
      return;
    },
    // 获取account
    getAccount: function getAccount() {
      var account = accountStorage.getCacheUserInfo();
      if (account) {
        return account.account;
      }
      return;
    },
    getSelectAll: function getSelectAll() {
      var _this2 = this;
      this.$confirm('是否仅看未读信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this2.readStatus = 0;
        _this2.getMessageList();
        // 成功
      }).catch(function (err) {
        // 失败
      });
    },
    // 分页
    handleChange: function handleChange(val) {
      this.current = val;
      if (this.type === 3) {
        this.getPromotionActivity();
      } else {
        this.getMessageList();
      }
    },
    // 查看详情
    handleDetail: function handleDetail(item) {
      this.getUpdatePmMessagePool(item.id);
      this.$router.push({
        name: "messageCenterDetail",
        params: {
          id: item.id,
          status: item.status
        }
      });
    },
    setAllRead: function setAllRead(item) {
      var _this3 = this;
      var title = item == 1 ? '是否将未读消息置为已读？' : '是否清除已读？';
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        var url = '/xpaas-message-center/api/v1/message/pm/pool/updateStatusAll';
        var data = {
          appId: 1,
          type: _this3.status,
          companyAccount: Number(_this3.getAccount())
        };
        _this3.$getHttpClient().post(url, data).then(function (res) {
          location.reload();
        });
        // 成功
      }).catch(function (err) {
        // 失败
      });
    }
  }
};